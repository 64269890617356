<template>
	<div class="Projects">
		<div class="bgimg">
			<img src="../../assets/img/Project_bgimg.png" alt />
			<div class="aboutUs_tab">
				<div :class="[tab==0?'aboutUs_tab_item2':'aboutUs_tab_item']" @click="choosebtn(0)">运营期项目</div>
				<div :class="[tab==1?'aboutUs_tab_item2':'aboutUs_tab_item']" @click="choosebtn(1)">筹备期项目</div>
			</div>
		</div>

		<div class="Operating_Period_Projects" v-if="tab==0">
			<div class="courseDevelopment_title">
				<div class="courseDevelopment_title_style">运营期项目</div>
				<div>Projects in Operation</div>
			</div>

			<div class="Projects_items">
				<div class="Projects_items">
					<img class="item_img1" src="../../assets/img/projects_item9.png" alt />
					<div class="item_img2">
						<img src="../../assets/img/projects_item_bgimg_right.png" alt />
						<div class="item_content">
							<div class="item_content_title">季佳 · 荟 | 烽火</div>
							<div class="item_content_text">
								{{"季佳·荟烽火地处武汉市洪山区白沙洲大道与江盛路十字路口,以周边地缘客群、家庭客群作为主要目标客群,强调生活、商业的交流与融合涵盖精品零售、优享美食、休闲娱乐、品质生活、儿童成长五大类业态,致力于为消费者打造一处便捷舒适、悦享生活的社区商业体验中心 。" | ellipsis(182)}}
							</div>
							<div class="item_content_look">
								<a href="http://www.ysdgo.com/#/2d8dc457e5d4a58d/marketDetails?id=85" target="_blank">
									<button class="item_content_lookbtn">查看详情</button>
								</a>
							</div>
						</div>
					</div>
				</div>
				<div class="Projects_items">
					<img class="item_img1 Projects_items_style" src="../../assets/img/projects_item8.png" alt />

					<div class="item_img2" style="left:0px">
						<img src="../../assets/img/projects_item_bgimg_left.png" alt />
						<div class="item_content item_content_style">
							<div class="item_content_title">季佳 · 荟 | 新华家园</div>
							<div class="item_content_text">
								{{"季佳 · 荟 | 新华家园项目位于武汉市汉口城区核心居住版块之一—杨汊湖片区，城市主干道姑嫂树路与红旗渠路交汇处，亿事达集团将以关注生活化家庭消费为核心，以儿童成长需求及亲子家庭需求为出发点，构建集合体验游乐、素质教育、亲子互动、精品购物、优享美食五大功能为一体的一站式家庭生活体验中心。" | ellipsis(182)}}
							</div>
							<div class="item_content_look">
								<a href="http://www.ysdgo.com/#/2d8dc457e5d4a58d/marketDetails?id=77" target="_blank">
									<button class="item_content_lookbtn">查看详情</button>
								</a>
							</div>
						</div>
					</div>
				</div>
				<div class="Projects_items">
					<img class="item_img1" src="../../assets/img/Clipboard4@2x.jpg" alt />

					<div class="item_img2">
						<img src="../../assets/img/projects_item_bgimg_right.png" alt />
						<div class="item_content">
							<div class="item_content_title">季佳 · 荟 | 百步亭</div>
							<div class="item_content_text">
								{{"季佳 · 荟 | 百步亭位于后湖大道与百步亭花园路交汇处，与地铁21号线百步亭花园路站无缝对接。经营面积4万方，覆盖餐饮美食、精品百货、儿童游乐、教育培训、健康体验等多功能业态。项目以“品质生活·多彩邻里”为口号，营造潮玩艺术与主题特色相结合的跨界体验场景，打造干净、舒适的购物环境，充分满足百步亭居民日常所需。目前，盒马鲜生、海底捞已盛大入驻。" | ellipsis(182)}}
							</div>
							<div class="item_content_look">
								<a href="http://www.ysdgo.com/#/2d8dc457e5d4a58d/marketDetails?id=63" target="_blank">
									<button class="item_content_lookbtn">查看详情</button>
								</a>
							</div>
						</div>
					</div>
				</div>

				<div class="Projects_items">
					<img class="item_img1 Projects_items_style" src="../../assets/img/projects_item2.png" alt />

					<div class="item_img2" style="left:0px">
						<img src="../../assets/img/projects_item_bgimg_left.png" alt />
						<div class="item_content item_content_style">
							<div class="item_content_title">季佳 · 荟 | 雄楚</div>
							<div class="item_content_text">
								{{"季佳 · 荟 | 雄楚紧邻雄楚大道，距离光谷商圈约一公里，周边为武汉高校最为集中片区，公共基础设施配套齐全，以“新体验、新生活、新零售”的理念为主导，在业态规划与品牌上，焦距周边客群需求，打造为时尚家庭的品质生活区，海底捞、悦活里、IMX影院，百货零售一应俱全。现已成为多功能区域商业中心。" | ellipsis(182)}}
							</div>
							<div class="item_content_look">
								<a href="http://www.ysdgo.com/#/2d8dc457e5d4a58d/marketDetails?id=66" target="_blank">
									<button class="item_content_lookbtn">查看详情</button>
								</a>
							</div>
						</div>
					</div>
				</div>

				<div class="Projects_items">
					<img class="item_img1" src="../../assets/img/projects_item3.jpg" alt />

					<div class="item_img2">
						<img src="../../assets/img/projects_item_bgimg_right.png" alt />
						<div class="item_content">
							<div class="item_content_title">季佳 · 荟 | 华林广场</div>
							<div class="item_content_text">
								{{"季佳 · 荟 | 华林广场定位为“体验式垂直魔方商业”，总建筑面积约为5万平方米，400余个停车位，多部电梯可通达每间商铺，独立空调及动线，可保障商户24小时营业。由世界百强东呈酒店集团旗下宜尚酒店为领衔主力店，是一个集特色餐饮、休闲娱乐、亲子教育、健康养生、精英职场等复合业态于一体的新型邻里中心级商业。" | ellipsis(182)}}
							</div>
							<div class="item_content_look">
								<a href="http://www.ysdgo.com/#/2d8dc457e5d4a58d/marketDetails?id=64" target="_blank">
									<button class="item_content_lookbtn">查看详情</button>
								</a>
							</div>
						</div>
					</div>
				</div>

				<div class="Projects_items">
					<img class="item_img1 Projects_items_style" src="../../assets/img/projects_item4.png" alt />

					<div class="item_img2" style="left:0px">
						<img src="../../assets/img/projects_item_bgimg_left.png" alt />
						<div class="item_content item_content_style">
							<div class="item_content_title">季佳 · 里 | 美好广场</div>
							<div class="item_content_text">
								{{"季佳 · 里 | 美好广场位于紧邻城市二环线坐拥黄金地段，近靠墨水湖、武汉动物园等资源景观及公共配套设施，具备得天独厚的优势，由武汉红骏马集团、美好集团、亿事达集团共同打造，总建筑面积约7.26万平方米，共42层，其中地上40层，地下2层。项目委托亿事达集团旗下商业运营管理有限公司负责商业板块管理，是亿事达集团旗下轻资产项目又一力作。" | ellipsis(182)}}
							</div>
							<div class="item_content_look">
								<a href="http://www.ysdgo.com/#/2d8dc457e5d4a58d/marketDetails?id=62" target="_blank">
									<button class="item_content_lookbtn">查看详情</button>
								</a>
							</div>
						</div>
					</div>
				</div>

				<div class="Projects_items">
					<img class="item_img1" src="../../assets/img/projects_item1.png" alt />

					<div class="item_img2">
						<img src="../../assets/img/projects_item_bgimg_right.png" alt />
						<div class="item_content">
							<div class="item_content_title">季佳 · 里 | 新荣</div>
							<div class="item_content_text">
								{{"季佳 · 里 | 新荣是亿事达集团的第一个情景化主题街区，以亲近感、品质感的美好生活街区为核心定位，以开放、互动、场景、体验为要求，新增绚丽的灯光亮化、温馨的露天外摆与时尚的售卖商亭，营造更舒适、有温度的社区氛围。虹桥荟秉承人文服务基因，回归商业服务本质，以“邻里生活”为服务理念，构建独有的服务体系，打造最贴心的的生活关键，提供最人性化的生活场景，带来具有“亲近感”的服务体验，让消费者爱上这座“缤纷生活乐园”。" | ellipsis(182)}}
							</div>
							<div class="item_content_look">
								<a href="http://www.ysdgo.com/#/2d8dc457e5d4a58d/marketDetails?id=65" target="_blank">
									<button class="item_content_lookbtn">查看详情</button>
								</a>
							</div>
						</div>
					</div>
				</div>

				<div class="Projects_items">
					<img class="item_img1 Projects_items_style" src="../../assets/img/projects_item6.png" alt />

					<div class="item_img2" style="left:0px">
						<img src="../../assets/img/projects_item_bgimg_left.png" alt />
						<div class="item_content item_content_style">
							<div class="item_content_title">季佳 · 里 | 纸坊</div>
							<div class="item_content_text">
								{{"季佳 · 里 | 纸坊(原名华汇广场)的地段属于江夏核心一级商圈，亿事达为其打造出完整性的商业计划，品牌落位清晰，定位精准，长期化品牌活动和消费力让商业呈现质的变化。" | ellipsis(182)}}
							</div>
							<div class="item_content_look">
								<a href="http://www.ysdgo.com/#/2d8dc457e5d4a58d/marketDetails?id=76" target="_blank">
									<button class="item_content_lookbtn">查看详情</button>
								</a>
							</div>
						</div>
					</div>
				</div>
				
				<div class="Projects_items">
					<img class="item_img1" src="../../assets/img/projects_item11.png" alt />
				
					<div class="item_img2">
						<img src="../../assets/img/projects_item_bgimg_right.png" alt />
						<div class="item_content">
							<div class="item_content_title">季佳 · 里 | 汉桥小镇</div>
							<div class="item_content_text">
								{{"季佳 · 里 | 汉桥小镇位于武汉市汉阳区桃花街，商业面积2万方，是一条约为280M的商业步行街，吃货的理想社交场所，周边家庭的美好生活区以爱情、友情、亲情为诉求，建立社交、休闲、游憩新环境，打造桃花岛区域优质生活先驱，引领全新生活方式的休闲街区。" | ellipsis(182)}}
							</div>
							<div class="item_content_look">
								<a href="http://www.ysdgo.com/#/2d8dc457e5d4a58d/marketDetails?id=74" target="_blank">
									<button class="item_content_lookbtn">查看详情</button>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="Operating_Period_Projects" v-if="tab==1">
			<div class="courseDevelopment_title">
				<div class="courseDevelopment_title_style">筹备期项目</div>
				<div>Projects in Preparation</div>
			</div>

			<div class="Projects_items">
				<div class="Projects_items">
					<img class="item_img1" src="../../assets/img/projects_jh.png" alt />
				
					<div class="item_img2">
						<img src="../../assets/img/projects_item_bgimg_right.png" alt />
						<div class="item_content">
							<div class="item_content_title">季佳 · 里 | 建和</div>
							<div class="item_content_text">
								{{"季佳 · 里 | 建和项目位于武汉市洪山区青菱新城内，针对周边居民量身定制，旨在成为消费者日常及社交欢聚的纽带，以黏性、多样、快速更新的商业服务，将品质感和美好场景注入日常所需。" | ellipsis(182)}}
							</div>
							<div class="item_content_look">
								<!-- <a href="http://www.ysdgo.com/#/2d8dc457e5d4a58d/marketDetails?id=74" target="_blank"> -->
									<button class="disabledBtn">查看详情</button>
								<!-- </a> -->
							</div>
						</div>
					</div>
				</div>
				<div class="Projects_items">
					<img class="item_img1 Projects_items_style" src="../../assets/img/projects_item10.png" alt />

					<div class="item_img2" style="left:0px">
						<img src="../../assets/img/projects_item_bgimg_left.png" alt />
						<div class="item_content item_content_style">
							<div class="item_content_title">季佳 · 里 | 古田</div>
							<div class="item_content_text">
								{{"季佳 · 里 | 古田位于武汉市硚口区解放大道古田二路轻轨站口,雄踞古田核心商圈,与1号线无缝对接。项目周边社区、强企林立,精英消费旺盛,底商、街商及集中商业布局浑然一体。打破传统的商业设计理念,通过天街云廊及独特的设计手法,弱化内外街的概念,打造24小时活力商业街区,以“嗨吃、潮玩、时尚、创意”为主线,打造成以新鲜、时尚、创意为主题,以新生活方式作为内涵的体验型主题商街。从空间、色彩维度成为玩趣体验的专属空间。" | ellipsis(182)}}
							</div>
							<div class="item_content_look">
								<a href="http://www.ysdgo.com/#/2d8dc457e5d4a58d/marketDetails?id=75" target="_blank">
									<button class="item_content_lookbtn">查看详情</button>
								</a>
							</div>
						</div>
					</div>
				</div>
				<div class="Projects_items">
					<img class="item_img1" src="../../assets/img/projects_item7.png" alt />
					<div class="item_img2">
						<img src="../../assets/img/projects_item_bgimg_right.png" alt />
						<div class="item_content">
							<div class="item_content_title">季佳广场 | 汉桥</div>
							<div class="item_content_text">
								{{"季佳广场 | 汉桥位于汉阳核心区域麒麟路，总建筑面积10万方，包括裙楼商业广场4万方，星级酒店和甲级写字楼各3万方，由汉桥集团投资建设，亿事达集团将汇集江城各类美食，将汉桥广场打造成功能性餐饮、休闲、购物的时尚聚集地，为周边居民提供“美食+社交+娱乐”的全方位生活服务，力求在2020年为周边居民呈现佳作。" | ellipsis(182)}}
							</div>
							<div class="item_content_look">
								<!-- <a href="http://www.ysdgo.com/#/2d8dc457e5d4a58d/marketDetails?id=74" target="_blank"> -->
									<button class="disabledBtn">查看详情</button>
								<!-- </a> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Cookies from "js-cookie";

	export default {
		name: "Projects",
		metaInfo: {
			title: "商业项目", // set a title
			meta: [{
					// set meta
					name: "keyWords",
					content: "亿事达商业项目,商业项目,亿事达商业,招商,商业运营,季佳·荟,季佳·里,"
				},
				{
					name: "description",
					content: "亿事达集团商业项目。"
				}
			]
		},
		data() {
			return {
				tab: 0
			};
		},
		created() {},
		mounted() {
			this.$store.commit("updateTab", 3);
			if (this.$route.query.subtab) {
				this.tab = this.$route.query.subtab;
			}
		},
		filters: {
			ellipsis(value, num) {
				if (value.length > num) {
					return value.substring(0, num) + "...";
				} else {
					return value;
				}
			}
		},
		methods: {
			// 判断元素距离窗口的位置
			isElemVisible(el) {
				var rect = el.getBoundingClientRect();
				var elemTop = rect.top + 200; // 200 = buffer
				var elemBottom = rect.bottom;
				return elemTop < window.innerHeight && elemBottom >= 0;
			},
			choosebtn(tab) {
				this.tab = tab;
			}
		}
	};
</script>
<style scoped lang="scss">
	$vm_w_base: 1920;
	$vm_h_base: 1080;

	@function vm_w($px) {
		@return ($px / 1920) * 100vw;
	}

	@keyframes show-in {
		from {
			opacity: 0;
			filter: alpha(opacity=0);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		}

		to {
			opacity: 1;
			filter: alpha(opacity=100);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=100);
		}
	}

	@keyframes fade-in {
		from {
			transform: translateY(vm_w(30));
			-webkit-box-transform: translateY(vm_w(30));
			-moz-box-transform: translateY(vm_w(30));
			-ms-box-transform: translateY(vm_w(30));
			-o-transform: translateY(vm_w(30));
			opacity: 0;
			filter: alpha(opacity=0);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		}

		to {
			transform: translateY(0);
			-webkit-box-transform: translateY(0);
			-moz-box-transform: translateY(0);
			-ms-box-transform: translateY(0);
			-o-transform: translateY(0);
			opacity: 1;
			filter: alpha(opacity=100);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=100);
		}
	}

	@keyframes show-in3 {
		from {
			opacity: 0;
			filter: alpha(opacity=0);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
			transform: translateX(30px);
			-webkit-box-transform: translateX(30px);
			-moz-box-transform: translateX(30px);
			-ms-box-transform: translateX(30px);
			-o-transform: translateX(30px);
		}

		to {
			opacity: 1;
			filter: alpha(opacity=100);
			filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=100);
			transform: translateX(0px);
			-webkit-box-transform: translateX(0px);
			-moz-box-transform: translateX(0px);
			-ms-box-transform: translateX(0px);
			-o-transform: translateX(0px);
		}
	}

	.bgimg {
		width: 100%;
		position: relative;
	}

	.bgimg img {
		width: 100%;
	}

	.aboutUs_tab {
		width: vm_w(1200);
		height: vm_w(64);
		background-color: #ffffff;
		position: absolute;
		bottom: vm_w(-32);
		z-index: 10;
		left: calc(50% - #{vm_w(600)});
		left: -webkit-calc(50% - #{vm_w(600)});
		left: -moz-calc(50% - #{vm_w(600)});
		left: -ms-calc(50% - #{vm_w(600)});
		left: -o-calc(50% - #{vm_w(600)});
		box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.08);
		-webkit-box-box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.08);
		-moz-box-box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.08);
		-ms-box-box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.08);
		-o-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.08);
	}

	.aboutUs_tab_item {
		width: 50%;
		height: 100%;
		line-height: vm_w(64);
		display: inline-block;
		vertical-align: top;
		text-align: center;
		cursor: pointer;
		font-size: vm_w(16);
		font-family: Source Han Sans CN, Source Han Sans CN-Regular;
		font-weight: 400;
		color: #666666;
	}

	.aboutUs_tab_item2 {
		width: 50%;
		height: 100%;
		line-height: vm_w(64);
		display: inline-block;
		vertical-align: top;
		cursor: pointer;
		text-align: center;
		font-size: vm_w(16);
		font-family: Source Han Sans CN, Source Han Sans CN-Regular;
		font-weight: 400;
		color: $chooseColor;
	}

	.Operating_Period_Projects {
		width: vm_w(1200);
		padding-bottom: vm_w(64);
		margin: 0 auto;
	}

	.courseDevelopment_title_style {
		margin-top: vm_w(93);
		margin-bottom: vm_w(10);
	}

	.courseDevelopment_title {
		width: 100%;
		text-align: center;
		font-size: vm_w(32);
		font-family: Source Han Sans CN, Source Han Sans CN-Regular;
		font-weight: 400;
		opacity: 0;
		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
		-webkit-box-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
		-moz-box-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
		-ms-box-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
		-o-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
		animation-fill-mode: forwards;
		-webkit-box-animation-fill-mode: forwards;
		-moz-box-animation-fill-mode: forwards;
		-ms-box-animation-fill-mode: forwards;
		-o-animation-fill-mode: forwards;
	}

	.Projects_items_style {
		margin-left: vm_w(510);
	}

	.Projects_items {
		width: 100%;
		position: relative;
		margin-top: vm_w(32);
	}

	.item_img1 {
		width: vm_w(682);
		height: vm_w(487);
		vertical-align: top;
		opacity: 0;
		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		animation: show-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
		-webkit-box-animation: show-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
		-moz-box-animation: show-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
		-ms-box-animation: show-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
		-o-animation: show-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.1s;
		animation-fill-mode: forwards;
		-webkit-box-animation-fill-mode: forwards;
		-moz-box-animation-fill-mode: forwards;
		-ms-box-animation-fill-mode: forwards;
		-o-animation-fill-mode: forwards;
	}

	.item_content_style {
		left: vm_w(34) !important;
	}

	.item_img2 {
		width: vm_w(705);
		height: vm_w(270);
		position: absolute;
		top: vm_w(148);
		z-index: 10;
		left: vm_w(487);
		opacity: 0;
		filter: alpha(opacity=0);
		filter: progid:DXImageTransform.Microsoft.Alpha(style=0, opacity=0);
		animation: show-in3 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.2s;
		-webkit-box-animation: show-in3 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.2s;
		-moz-box-animation: show-in3 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.2s;
		-ms-box-animation: show-in3 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.2s;
		-o-animation: show-in3 0.6s cubic-bezier(0.32, 0.94, 0.6, 1) 0.2s;
		animation-fill-mode: forwards;
		-webkit-box-animation-fill-mode: forwards;
		-moz-box-animation-fill-mode: forwards;
		-ms-box-animation-fill-mode: forwards;
		-o-animation-fill-mode: forwards;
	}

	.item_img2 img {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
	}

	.item_content {
		width: vm_w(512);
		position: absolute;
		top: vm_w(33);
		left: vm_w(159);
	}

	.item_content_title {
		font-size: vm_w(20);
		font-family: Source Han Sans CN, Source Han Sans CN-Medium;
		font-weight: 500;
		color: #333333;
	}

	.item_content_text {
		font-size: vm_w(14);
		margin-top: 5px;
		font-family: Source Han Sans CN, Source Han Sans CN-Normal;
		color: #666666;
		line-height: vm_w(24);
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 5;
	}

	.item_content_lookbtn {
		width: vm_w(96);
		height: vm_w(32);
		line-height: vm_w(30);
		border: vm_w(1) solid #01ab94;
		box-sizing: border-box;
		cursor: pointer;
		background-color: #f5f5f5;
		border-radius: 4px;
		color: #01ab94;
		box-sizing: border-box;
		margin-top: vm_w(10);

		&:hover {
			background-color: #01ab94;
			color: #ffffff;
		}
	}
	.disabledBtn{
		width: vm_w(96);
		height: vm_w(32);
		line-height: vm_w(30);
		border: 1px solid #c2c2c2;
		color: #C2C2C2;
		margin-top: vm_w(10);
		border-radius: 4px;
	}
</style>
